<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="name">Name</label>
            <input
                id="name"
                v-model="exercise.name"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': validation.exercise.name.$error
                }"
                placeholder="Name"
                @blur="$emit('on-name-change', exercise.name)"
            />
        </div>
        <div class="form-group col-6">
            <label for="sets">Sets</label>
            <input
                id="sets"
                v-model="exercise.sets"
                type="number"
                class="form-control"
                :class="{
                    'is-invalid': validation.exercise.sets.$error
                }"
                placeholder="Sets"
            />
        </div>
        <div class="form-group col-6">
            <label for="repetitions">Repetitions</label>
            <input
                id="repetitions"
                v-model="exercise.repetitions"
                type="number"
                class="form-control"
                :class="{
                    'is-invalid': validation.exercise.repetitions.$error
                }"
                placeholder="Repetitions"
            />
        </div>

        <div class="col-12 pr-0">
            <h5 class="col-12 text-uppercase text-center bg-light p-2">
                Content
            </h5>
        </div>

        <label class="ml-2 mb-0">
            Exercise Image
        </label>
        <media-chooser v-model="exercise.image" add-buff class="col-12" />

        <div v-if="exercise.video" class="col-12 px-0">
            <video-content v-model="exercise.video" />
        </div>

        <div class="form-group col-6 mt-2">
            <input v-model="newStageName" type="text" class="form-control" />
        </div>
        <div class="form-group col-6 d-flex align-items-stretch">
            <button
                type="button"
                class="mx-0 btn btn-sm btn-success waves-effect waves-light mt-2"
                @click="addStage"
            >
                <i class="mdi mdi-plus" />
                Add Stage
            </button>
        </div>

        <div
            v-for="(stage, index) in exercise.stages"
            :key="index"
            class="col-12 row ml-3 pr-3"
        >
            <div class="col-12 pr-0">
                <h5
                    class="col-12 d-flex align-items-center justify-content-between bg-light p-2"
                >
                    {{ stage.name }}
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="deleteStage(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </h5>
            </div>
            <div class="form-group col-12">
                <label>
                    Stage Name
                </label>
                <input
                    v-model="exercise.stages[index].name"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="exercise.stages[index].description"
                    label="Stage Description"
                />
            </div>
            <label class="ml-2 mb-0">
                Stage Image
            </label>
            <media-chooser
                v-model="exercise.stages[index].image"
                add-buff
                class="col-12"
            />
        </div>

        <div class="col-12">
            <h5 class="col-12 text-uppercase text-center bg-light p-2">
                Bottom Banner
            </h5>
        </div>

        <div class="form-group col-12 mt-2">
            <b-form-checkbox
                v-model="exercise.banner.enabled"
                name="sticky"
                switch
            >
                Enabled
            </b-form-checkbox>
        </div>
        <div class="form-group col-12">
            <label>
                Title
            </label>
            <input
                v-model="exercise.banner.title"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-6">
            <label>
                Button Text
            </label>
            <input
                v-model="exercise.banner.buttonText"
                type="text"
                class="form-control"
            />
        </div>
        <div class="form-group col-6">
            <label>
                Button Link
            </label>
            <input
                v-model="exercise.banner.buttonLink"
                type="text"
                class="form-control"
            />
        </div>
    </div>
</template>

<script>
import DraggableList from '@components/elements/DraggableList';
import MediaChooser from '@components/media/MediaChooser';
import Video from '@components/elements/contents-builder-elements/Video';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        DraggableList,
        MediaChooser,
        VideoContent: Video,
        WysiwygEditor
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        validation: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            exercise: {
                banner: {},
                stages: []
            },
            exercises: [],
            newStageName: ''
        };
    },

    watch: {
        exercise() {
            this.$emit('input', this.exercise);
        }
    },

    mounted() {
        this.exercise = { ...this.value };

        if (!this.exercise.stages) {
            this.exercise.stages = [];
        }
    },

    methods: {
        addStage() {
            if (!this.newStageName) {
                return;
            }

            this.exercise.stages.push({
                name: this.newStageName,
                description: '',
                image: {}
            });

            this.newStageName = '';
        },

        deleteStage(index) {
            this.exercise.stages.splice(index, 1);
        }
    }
};
</script>
