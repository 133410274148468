<template>
    <ol class="list-group my-2">
        <draggable
            :list="draggableElements"
            ghost-class="ghost"
            v-bind="dragOptions"
            @end="onElementMoved"
            @start="drag = true"
        >
            <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
            >
                <a
                    v-for="element in draggableElements"
                    :key="element.id"
                    href="#"
                    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    @click.prevent="$emit('element-clicked', element)"
                >
                    <div class="d-flex">
                        <slot :element="element">
                            {{ element }}
                        </slot>
                    </div>
                    <div>
                        <slot name="additionalActions" :element="element" />
                        <a
                            v-if="isEditable"
                            class="action-icon"
                            @click.prevent="onEditElementButton(element.id)"
                        >
                            <i class="fas fa-edit" />
                        </a>
                        <a
                            class="action-icon"
                            @click.prevent="onDeleteElementButton(element.id)"
                        >
                            <i class="far fa-trash-alt" />
                        </a>
                    </div>
                </a>
            </transition-group>
        </draggable>
    </ol>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable
    },

    props: {
        items: {
            type: [Array, Object],
            required: true
        },
        isEditable: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            draggableElements: null,
            drag: false
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    },

    mounted() {
        this.draggableElements = [...this.items];
    },

    methods: {
        async onDeleteElementButton(id) {
            const confirmed = await this.$confirmationModal(
                'Are you sure you want to delete this element?'
            );

            if (confirmed) {
                this.$emit('element-deleted', id);
            }
        },

        async onEditElementButton(id) {
            this.$emit('edit-element', id);
        },

        onElementMoved() {
            this.drag = false;

            this.$emit('element-moved', {
                orderedList: this.draggableElements
            });
        }
    }
};
</script>

<style scoped lang="scss">
.ghost {
    cursor: pointer;
    opacity: 0.5;
    background: #c8ebfb;
}

.button {
    margin-top: 35px;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;

    i {
        cursor: pointer;
    }
}
</style>
