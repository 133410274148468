<template>
    <div class="card" style="background-color: #fafafa">
        <div class="card-body">
            <div v-if="sections.status" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Status
                </h5>
                <div class="col-12">
                    <div class="d-flex flex-column mb-3">
                        <div
                            v-if="item.created_at"
                            class="d-flex flex-wrap my-2"
                        >
                            <div class="mr-1">
                                <b>Created at: </b>
                            </div>
                            <div>
                                {{ item.created_at | formatDate }}
                            </div>
                        </div>
                        <div
                            v-if="item.updated_at"
                            class="d-flex flex-wrap my-2"
                        >
                            <div class="mr-1">
                                <b>Last update at:</b>
                            </div>
                            <div>
                                {{ item.updated_at | formatDate }}
                            </div>
                        </div>
                        <div v-if="item.author" class="d-flex flex-wrap my-2">
                            <div class="mr-1">
                                <b>Created by:</b>
                            </div>
                            {{ item.author }}
                        </div>
                    </div>
                    <div
                        v-if="componentOptions.deleteButton"
                        class="col-12 d-flex justify-content-center"
                    >
                        <button
                            type="button"
                            class="btn btn-danger btn-static waves-effect waves-light mb-4"
                            @click="onDeleteElement"
                        >
                            <i class="far fa-trash-alt" />
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="sections.customAuthor" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Custom author name
                </h5>
                <div class="form-group col-12">
                    <div class="input-group">
                        <input
                            :value="customAuthorName"
                            type="text"
                            class="form-control"
                            placeholder="Custom author name"
                            @blur="onCustomAuthorNameBlur"
                        />
                    </div>
                </div>
            </div>
            <div v-if="sections.permalink" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Permalink
                </h5>
                <div class="form-group col-12">
                    <label for="slug">URL Slug</label>
                    <div class="input-group">
                        <input
                            id="slug"
                            v-model="newSlug"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': slugError
                            }"
                            placeholder="URL slug"
                            @input="onSlugChange"
                            @blur="onSlugBlur"
                        />
                        <div class="invalid-feedback">
                            {{ slugError }}
                        </div>
                    </div>
                    <div class="mt-2">
                        {{ slugUrl }}
                    </div>
                </div>
            </div>
            <div v-if="sections.featuredImage" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Featured Image
                </h5>
                <div class="col-12">
                    <div class="form-group">
                        <media-chooser
                            v-model="image"
                            type="image"
                            :id-only="true"
                        />
                    </div>
                </div>
                <div v-if="sections.imageAuthor" class="col-12">
                    <div class="form-group">
                        <label for="imageAuthor">Image Author</label>
                        <input
                            :value="imageAuthor"
                            type="text"
                            class="form-control"
                            placeholder="Image author name"
                            @blur="onImageAuthorBlur"
                        />
                    </div>
                </div>
            </div>
            <div v-if="sections.categories" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Categories
                </h5>
                <div class="col-12 ml-3 row">
                    <div style="max-height: 250px; overflow-y: scroll">
                        <div
                            v-for="category in allCategories"
                            :key="category.id"
                            class="col-12"
                            style="cursor: default"
                        >
                            <input
                                :id="`${category.id}_tag`"
                                type="checkbox"
                                :checked="isCategorySelected(category.id)"
                                @click="
                                    toggleCategory(
                                        category.id,
                                        null,
                                        category.subcategories
                                    )
                                "
                            />
                            <label :for="`${category.id}_tag`" class="ml-1">
                                {{ category.name }}
                            </label>
                            <div
                                v-if="
                                    category.subcategories &&
                                        category.subcategories.length
                                "
                                class="col-12 row"
                            >
                                <div
                                    v-for="sub in category.subcategories"
                                    :key="sub.id"
                                    class="col-12"
                                    style="cursor: default"
                                >
                                    <input
                                        :id="`${sub.id}_tag`"
                                        type="checkbox"
                                        :checked="isCategorySelected(sub.id)"
                                        @click="
                                            toggleCategory(sub.id, category.id)
                                        "
                                    />
                                    <label :for="`${sub.id}_tag`" class="ml-1">
                                        {{ sub.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="sections.tags" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Tags
                </h5>
                <div class="col-12">
                    <v-select
                        v-model="tagsCopy"
                        :multiple="true"
                        label="name"
                        :close-on-select="false"
                        :no-drop="!tagsSearchResult.length"
                        :options="tagsSearchResult"
                        @search="onTagSearch"
                    />
                </div>
            </div>
            <div v-if="sections.meta" class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Meta
                </h5>
                <div class="form-group col-12">
                    <label for="pageTitle">Page title</label>
                    <div class="input-group">
                        <input
                            id="pageTitle"
                            v-model="metaCopy.title"
                            type="text"
                            class="form-control"
                            dir="auto"
                            placeholder="Page title"
                        />
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="pageDescription">Page description</label>
                    <div class="input-group">
                        <textarea
                            id="pageDescription"
                            v-model="metaCopy.description"
                            type="text"
                            class="form-control"
                            dir="auto"
                            rows="5"
                            placeholder="Page description"
                        />
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="canonicalUrl">Canonical URL</label>
                    <div class="input-group">
                        <input
                            id="canonicalUrl"
                            v-model="metaCopy.canonicalUrl"
                            type="text"
                            class="form-control"
                            dir="auto"
                            placeholder="Canonical URL"
                        />
                    </div>
                </div>
                <div class="form-group col-12">
                    <b-form-checkbox v-model="metaCopy.noFollow" switch>
                        No Follow
                    </b-form-checkbox>
                </div>
                <div class="form-group col-12">
                    <b-form-checkbox v-model="metaCopy.noIndex" switch>
                        No Index
                    </b-form-checkbox>
                </div>
                <div class="form-group col-12">
                    <label for="ogTitle">Open Graph title</label>
                    <div class="input-group">
                        <input
                            id="ogTitle"
                            v-model="metaCopy.ogTitle"
                            type="text"
                            class="form-control"
                            dir="auto"
                            placeholder="Open Graph title"
                        />
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="ogDescription">Open Graph description</label>
                    <div class="input-group">
                        <textarea
                            id="ogDescription"
                            v-model="metaCopy.ogDescription"
                            type="text"
                            class="form-control"
                            dir="auto"
                            rows="5"
                            placeholder="Open Graph description"
                        />
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="ogImage">Open Graph image</label>
                    <media-chooser v-model="metaCopy.ogImage" type="image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import slug from 'slug';

export default {
    props: {
        sections: {
            type: Object,
            required: false,
            default: () => ({
                status: false,
                permalink: false,
                featuredImage: false,
                imageAuthor: false,
                categories: false,
                meta: false,
                customAuthor: false
            })
        },
        slug: {
            type: String,
            required: false,
            default: ''
        },
        slugError: {
            type: String,
            required: false,
            default: ''
        },
        item: {
            type: Object,
            required: false,
            default: () => ({})
        },
        featuredImage: {
            type: String,
            required: false,
            default: ''
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        },
        getCategoriesFunction: {
            type: Function,
            required: false,
            default: () => ({
                rows: [],
                count: 0
            })
        },
        categories: {
            type: Array,
            required: false,
            default: () => []
        },
        tags: {
            type: Array,
            required: false,
            default: () => []
        },
        customAuthorName: {
            type: String,
            required: false,
            default: ''
        },
        imageAuthor: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            newSlug: '',
            image: '',
            defaultOptions: {
                featuredImagePath: '',
                deleteButton: true,
                slugBasicUrl: ''
            },
            allCategories: [],
            selectedCategories: [],
            tagsCopy: [],
            metaCopy: {},
            tagsSearchResult: []
        };
    },

    computed: {
        slugUrl() {
            if (!this.componentOptions.slugBasicUrl) {
                return `${process.env.VUE_APP_FRONTEND_URL}/${this.newSlug ||
                    '_______'}`;
            }

            return `${process.env.VUE_APP_FRONTEND_URL}/${
                this.componentOptions.slugBasicUrl
            }/${this.newSlug || '_______'}`;
        },
        componentOptions() {
            return { ...this.defaultOptions, ...this.options };
        }
    },

    watch: {
        image() {
            this.$emit('on-featured-image-change', this.image);
        },
        tagsCopy() {
            this.$emit('on-tags-change', this.tagsCopy);
        },
        slug: {
            handler() {
                this.newSlug = this.slug;

                this.onSlugBlur();
            },
            immediate: true
        },
        metaCopy: {
            handler() {
                this.$emit('meta-change', this.metaCopy);
            },
            deep: true
        }
    },

    async created() {
        if (this.featuredImage) {
            this.image = this.featuredImage;
        }

        if (this.sections.categories) {
            try {
                const categories = await this.getCategoriesFunction({});

                this.allCategories = [...categories.rows];
                this.selectedCategories = [...this.categories];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }

        if (this.tags.length) {
            this.tagsCopy = [...this.tags];
        }

        if (typeof this.item.meta === 'object') {
            this.metaCopy = { ...this.item.meta };
        }
    },

    methods: {
        ...mapActions({
            getTags: 'tags/index'
        }),

        onSlugChange() {
            this.$emit('on-slug-change', this.newSlug);
        },

        onSlugBlur() {
            if (this.newSlug) {
                this.newSlug = this.newSlug.toLowerCase();
            }

            this.newSlug = slug(this.newSlug);
            this.$emit('on-slug-change', this.newSlug);
        },

        isCategorySelected(id) {
            return this.selectedCategories.includes(id);
        },

        toggleCategory(id, parrentId = null, sub = []) {
            const index = this.selectedCategories.indexOf(id);

            if (!~index) {
                this.selectedCategories = [...this.selectedCategories, id];

                if (parrentId) {
                    const parentIndex = this.selectedCategories.indexOf(
                        parrentId
                    );

                    if (!~parentIndex) {
                        this.selectedCategories = [
                            ...this.selectedCategories,
                            parrentId
                        ];
                    }
                }
            } else {
                this.selectedCategories.splice(index, 1);

                if (sub.length) {
                    sub.forEach(item => {
                        const subIndex = this.selectedCategories.indexOf(
                            item.id
                        );

                        if (subIndex !== -1) {
                            this.selectedCategories.splice(subIndex, 1);
                        }
                    });
                }
            }

            this.$emit('on-categories-change', this.selectedCategories);
        },

        async onTagSearch(val) {
            if (!val) {
                this.tagsSearchResult = [];

                return;
            }

            try {
                const tags = await this.getTags({
                    perPage: 5,
                    page: 1,
                    search: val
                });

                this.tagsSearchResult = [...tags];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onDeleteElement() {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this item?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            this.$emit('on-delete-confirm')
        },

        onCustomAuthorNameBlur($e) {
            this.$emit('custom-author-name-change', $e.target.value);
        },

        onImageAuthorBlur($e) {
            this.$emit('on-image-author-change', $e.target.value);
        }
    }
};
</script>
