<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div v-if="!editMode || (editMode && exercise.id)" class="row">
            <div class="col-xl-9 mb-3">
                <div class="card h-100">
                    <div class="card-body">
                        <add-edit-exercise-form
                            v-model="exercise"
                            :edit-mode="editMode"
                            :validation="$v"
                            @on-name-change="onNameChange"
                        />
                    </div>
                </div>
            </div>

            <div class="col-xl-3 mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <a
                                v-if="editMode"
                                :href="previewUrl"
                                class="btn btn-primary btn-static waves-effect waves-light mr-1 mb-1"
                                target="_blank"
                                @click="onPreviewButtonClick"
                            >
                                <i class="far fa-eye" />
                                Preview
                            </a>
                            <button
                                class="btn btn-success btn-static waves-effect waves-light mb-1"
                                @click="submitHandler"
                            >
                                <i class="fas fa-save" />
                                Save
                            </button>
                        </div>

                        <item-information
                            :key="itemInformationKey"
                            :edit-mode="editMode"
                            :sections="sections"
                            :slug="slug"
                            :slug-error="slugError"
                            :item="exercise"
                            :options="options"
                            @on-slug-change="onSlugChange"
                            @on-delete-confirm="onDeleteConfirm"
                        />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import { required, integer } from 'vuelidate/lib/validators';
import config from '@/config';
import AddEditExerciseForm from '@components/exercises/AddEditExerciseForm';
import ItemInformation from '@components/elements/ItemInformation';

export default {
    page: {
        title: 'Add new exercise'
    },

    validations: {
        exercise: {
            name: {
                required
            },
            sets: {
                required,
                integer
            },
            repetitions: {
                required,
                integer
            }
        },
        slug: {
            required
        }
    },

    components: {
        AddEditExerciseForm,
        ItemInformation
    },

    data() {
        return {
            title: 'Add exercise',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Exercises',
                    to: '/exercises'
                },
                {
                    text: 'Add',
                    active: true
                }
            ],
            options: {
                slugBasicUrl: 'exercises'
            },
            editMode: false,
            exercise: {
                image: {},
                video: {},
                stages: [],
                banner: {}
            },
            slug: '',
            id: null,
            slugExists: false,
            exerciseValueStringify: '',
            itemInformationKey: 0
        };
    },

    computed: {
        sections() {
            const sections = {
                permalink: true
            };

            if (this.editMode) {
                sections.status = true;
            }

            return sections;
        },

        slugError() {
            if (this.$v.slug.$error) {
                return 'Slug is required.';
            }

            if (this.slugExists) {
                return 'Slug already exists.';
            }

            return '';
        },

        previewUrl() {
            return `${config.frontendUrl}/exercises/${this.slug}`;
        }
    },

    async created() {
        this.id = this.$route.params.id;

        if (this.id) {
            this.editMode = true;
            this.title = 'Edit exercise';
            this.items[this.items.length - 1].text = 'Edit';

            try {
                this.exercise = await this.getExercise(this.id);
                this.slug = this.exercise.slug;

                this.exerciseValueStringify = JSON.stringify(this.exercise);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    },

    methods: {
        ...mapActions({
            addExercise: 'exercises/store',
            deleteExercise: 'exercises/delete',
            getExercise: 'exercises/show',
            updateExercise: 'exercises/update'
        }),

        async submitHandler() {
            this.slugExists = false;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                this.exercise.slug = this.slug;

                if (!this.editMode) {
                    const added = await this.addExercise({
                        ...this.exercise
                    });

                    this.$router.push(`/exercises/${added.id}/edit`);
                } else {
                    const exercise = await this.updateExercise({
                        id: this.id,
                        exercise: { ...this.exercise }
                    });

                    this.exercise.updated_at = exercise.updated_at;
                    this.exerciseValueStringify = JSON.stringify(this.exercise);

                    this.$toaster('Exercise has been updated!');
                }
            } catch (error) {
                console.error(error);

                if (
                    error.response.status === 422 &&
                    error.response.data.errors &&
                    error.response.data.errors.slug
                ) {
                    this.slugExists = true;

                    return;
                }

                this.$toasterError();
            }
        },

        async onDeleteConfirm() {
            try {
                await this.deleteExercise(this.id);

                this.$router.push('/exercises');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onPreviewButtonClick(e) {
            if (this.exerciseValueStringify !== JSON.stringify(this.exercise)) {
                e.preventDefault();

                this.$toasterWarning('Please save changes before preview!');
            }
        },

        onNameChange(name) {
            if (!this.editMode && name) {
                this.slug = name;

                this.itemInformationKey += 1;
            }
        },

        onSlugChange(slug) {
            this.slug = slug;
            this.$v.slug.$reset();
        }
    }
};
</script>
